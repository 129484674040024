import React, { lazy } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Dashboard = lazy(() => import('../Dashboard'));
const Accepted = lazy(() => import('../Accepted'));
const Delayed = lazy(() => import('../Delayed'));
const Expired = lazy(() => import('../Expired'));
const Bounced = lazy(() => import('../Bounced'));
const Rejected = lazy(() => import('../Rejected'));
const RecipientsEngagement = lazy(() => import('../RecipientsEngagement'));
const MessageEvents = lazy(() => import('../MessageEvents'));
const Users = lazy(() => import('../Users'));
const EditUser = lazy(() => import('../Users/EditUser'));
const NewUser = lazy(() => import('../Users/NewUser'));
const ApiKeys = lazy(() => import('../ApiKeys'));
const Domains = lazy(() => import('../Domains'));
const EmailTemplates = lazy(() => import('../EmailTemplates'));
const MessageDetails = lazy(() => import('../MessageDetails'));
const NewTemplate = lazy(() => import('../EmailTemplates/NewTemplate'));
const SearchPage = lazy(() => import('../SearchPage'));
const Campaigns = lazy(() => import('../Campaigns'));
const CampaignAnalytics = lazy(() => import('../CampaignAnalytics '));
const RecipientsLists = lazy(() => import('../RecipientsLists'));

const AddNewList = lazy(() =>
	import('../RecipientsLists/components/AddNewList')
);

const AddNewContact = lazy(() =>
	import('../RecipientListDetails/components/AddNewContact')
);
const RecipientListDetails = lazy(() => import('../RecipientListDetails'));
const EditContactForm = lazy(() =>
	import(
		'../../shared/components/AddContactModalFormComponents/EditContactForm'
	)
);
const Contacts = lazy(() => import('../Contacts'));
const RecipientListComponents = lazy(() =>
	import(
		'../../shared/components/AddContactModalFormComponents/RecipientListComponents'
	)
);
const NewCampaign = lazy(() => import('../Campaigns/components/NewCampaign'));
const NoPermissions = lazy(() => import('../NoPermissions'));
const Suppressions = lazy(() => import('../Suppressions'));
const IpPools = lazy(() => import('../IpPools'));
const IpPoolView = lazy(() => import('../IpPools/components/IpPoolView'));
const Journey = lazy(() => import('../Journey'));

const ProtectedRoute = ({ children, ...rest }) => {
	const location = useLocation();

	let hasPermission = false;

	const type = useSelector(
		(state) => state.auth.user !== null && state.auth.user.type
	);

	const pathsMap = [
		{
			admin: [
				'/report/events',
				'/users',
				'/add_user',
				'/users/',
				'/email-templates',
				'/api-keys',
				'/domains',
				'/email-templates',
				'/email-templates/add',
				'/email-templates/edit/',
				'/search/',
				'/recipients-lists',
				'/contacts',
				'/campaings',
				'/campaing-analytics',
				'/suppressions',
				'/ip-pools',
				'/journey',
			],
			developer: [
				'/report/events',
				'/email-templates',
				'/api-keys',
				'/domains',
				'/email-templates',
				'/email-templates/add',
				'/email-templates/edit/',
				'/search/',
				'/recipients-lists',
				'/contacts',
				'/campaings',
				'/campaing-analytics',
				'/suppressions',
				'/ip-pools',
				'/journey',
			],
			manager: [
				'/email-templates',
				'/email-templates/add',
				'/email-templates/edit/',
				'/search/',
				'/recipients-lists',
				'/contacts',
				'/campaings',
				'/campaing-analytics',
				'/suppressions',
			],
			reporter: ['/email-templates'],
		},
	];

	let paths = [];

	pathsMap.filter((role) => (paths = role[type]));

	paths?.forEach((path) => {
		if (location.pathname.indexOf(path) >= 0) {
			hasPermission = true;
		}
	});

	return (
		<Route
			{...rest}
			render={({ location }) => {
				return hasPermission ? (
					children
				) : (
					<Redirect
						to={{ pathname: '/no-permissions', state: { from: location } }}
					/>
				);
			}}
		/>
	);
};

export const Pages = () => {
	const type = useSelector((state) =>
		state.auth.user ? state.auth.user.type : 'reporter'
	);

	return (
		<Switch>
			<Route path='/report/dashboard' component={Dashboard} />
			<Route path='/report/accepted' component={Accepted} />
			<Route path='/report/delayed' component={Delayed} />
			<Route path='/report/expired' component={Expired} />
			<Route path='/report/bounced' component={Bounced} />
			<Route path='/report/rejected' component={Rejected} />
			<Route
				path='/report/recipients_engagement'
				component={RecipientsEngagement}
			/>
			<ProtectedRoute exact path='/report/events'>
				<MessageEvents />
			</ProtectedRoute>
			<ProtectedRoute exact path='/users'>
				<Users />
			</ProtectedRoute>
			<ProtectedRoute exact path='/add_user'>
				<NewUser />
			</ProtectedRoute>
			<ProtectedRoute exact path='/users/:id'>
				<EditUser />
			</ProtectedRoute>
			<ProtectedRoute exact path='/api-keys'>
				<ApiKeys />
			</ProtectedRoute>
			<ProtectedRoute exact path='/domains'>
				<Domains />
			</ProtectedRoute>
			<ProtectedRoute exact path='/email-templates'>
				<EmailTemplates />
			</ProtectedRoute>
			<ProtectedRoute exact path='/email-templates/add'>
				<NewTemplate />
			</ProtectedRoute>
			<ProtectedRoute exact path='/email-templates/edit/:id'>
				<NewTemplate />
			</ProtectedRoute>
			<ProtectedRoute exact path='/email-templates/edit/:id'>
				<NewTemplate />
			</ProtectedRoute>

			<Route
				exact
				path='/message_details/:id'
				component={type !== 'reporter' ? MessageDetails : NoPermissions}
			/>

			<ProtectedRoute exact path='/search/:id'>
				<SearchPage />
			</ProtectedRoute>
			<ProtectedRoute exact path='/recipients-lists'>
				<RecipientsLists />
			</ProtectedRoute>

			<Route
				exact
				path='/recipients-lists/add_new_list'
				component={AddNewList}
			/>
			<Route
				exact
				path='/recipients-lists/add_new_contact'
				component={AddNewContact}
			/>
			<Route
				exact
				path='/recipients-lists/view'
				component={RecipientListDetails}
			/>
			<Route
				exact
				path='/recipients-lists/view_recipient/:id'
				component={EditContactForm}
			/>

			<ProtectedRoute exact path='/contacts'>
				<Contacts />
			</ProtectedRoute>

			<Route
				exact
				path='/contacts/view_recipient/:id'
				component={EditContactForm}
			/>
			<Route exact path='/contacts/add_new_contact' component={AddNewContact} />
			<Route
				exact
				path='/contacts/add_to_recipeint_list/:id'
				component={RecipientListComponents}
			/>
			<ProtectedRoute exact path='/campaings'>
				<Campaigns />
			</ProtectedRoute>
			<ProtectedRoute exact path='/campaing-analytics'>
				<CampaignAnalytics />
			</ProtectedRoute>
			<ProtectedRoute exact path='/journey'>
				<Journey />
			</ProtectedRoute>
			<ProtectedRoute exact path='/suppressions'>
				<Suppressions />
			</ProtectedRoute>

			<Route exact path='/campaings/add_new_campaign' component={NewCampaign} />
			<Route exact path='/no-permissions' component={NoPermissions} />

			<ProtectedRoute exact path='/ip-pools'>
				<IpPools />
			</ProtectedRoute>

			<Route exact path='/ip-pools/pool' component={IpPoolView} />
			<Route render={() => <Redirect to='/report/dashboard' />} />
		</Switch>
	);
};
