import {
	GET_CAMPAIGN_DATA_TABLE,
	GET_CAMPAIGN_DATA_TABLE_PENDING,
	GET_CAMPAIGN_DATA_TABLE_ERROR,
	INCREASE_CAMPAIGN_STEP,
	DECREASE_CAMPAIGN_STEP,
	UPDATE_CAMPAIGN_NAME,
	UPDATE_CAMPAIGN_TYPE,
	ADD_CAMPAIGN_SELECTED_ROW,
	REMOVE_CAMPAIGN_SELECTED_ROW,
	ADD_CAMPAIGN_TEMPLATE_TYPE,
	REMOVE_CAMPAIGN_TEMPLATE_TYPE,
	RESET_CAMPAIGN_TEMPLATE_TYPE,
	UPDATE_CAMPAIGN_ERROR,
	ADD_CAMPAIGN_DOMAIN,
	ADD_CAMPAIGN_ADDRESS,
	ADD_CAMPAIGN_SENDER,
	ADD_CAMPAIGN_SENDER_NAME,
	RESET_CAMPAIGN,
	EDIT_STANDART_CAMPAIGN,
	SET_CAMPAIGN_DELIVERY_DATA,
	SET_CAMPAIGN_DELIVERY_DATE,
	SET_CAMPAIGN_DELIVERY_TIMEZONE,
	SET_CAMPAIGN_DELIVERY_PERSONALIZE,
} from '../actions/constants';

const initialState = {
	name: '',
	type: '',
	listUids: [],
	templateUids: [],
	address: null,
	domain: '',
	senderName: '',
	senderUid: '',
	deliverySchedule: null,
	errors: false,
	activeStep: 0,
};

export const campaignReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_CAMPAIGN_TYPE:
			return { ...state, type: action.payload };
		case UPDATE_CAMPAIGN_NAME:
			return { ...state, name: action.payload };
		case UPDATE_CAMPAIGN_ERROR:
			return { ...state, errors: action.payload };
		case INCREASE_CAMPAIGN_STEP:
			return { ...state, activeStep: state.activeStep + 1 };
		case DECREASE_CAMPAIGN_STEP:
			return { ...state, activeStep: state.activeStep - 1 };
		case ADD_CAMPAIGN_SELECTED_ROW:
			return { ...state, listUids: [...state.listUids, action.payload] };
		case REMOVE_CAMPAIGN_SELECTED_ROW:
			return {
				...state,
				listUids: state.listUids.filter((item) => item._id !== action.payload),
			};

		case ADD_CAMPAIGN_TEMPLATE_TYPE:
			return {
				...state,
				templateUids: [...state.templateUids, action.payload],
			};
		case REMOVE_CAMPAIGN_TEMPLATE_TYPE:
			return {
				...state,
				templateUids: state.templateUids.filter(
					(item) => item !== action.payload
				),
			};
		case RESET_CAMPAIGN_TEMPLATE_TYPE:
			return {
				...state,
				templateUids: [],
			};

		case ADD_CAMPAIGN_ADDRESS:
			return { ...state, address: action.payload };
		case ADD_CAMPAIGN_DOMAIN:
			return { ...state, domain: action.payload };
		case ADD_CAMPAIGN_SENDER:
			return { ...state, senderUid: action.payload };
		case ADD_CAMPAIGN_SENDER_NAME:
			return { ...state, senderName: action.payload };
		case RESET_CAMPAIGN:
			return initialState;
		case EDIT_STANDART_CAMPAIGN:
			return action.payload;
		case SET_CAMPAIGN_DELIVERY_DATA:
			return { ...state, deliverySchedule: action.payload };
		case SET_CAMPAIGN_DELIVERY_DATE:
			return {
				...state,
				deliverySchedule: {
					...state.deliverySchedule,
					moment: action.payload,
				},
			};
		case SET_CAMPAIGN_DELIVERY_TIMEZONE:
			return {
				...state,
				deliverySchedule: {
					...state.deliverySchedule,
					timezone: action.payload,
				},
			};
		case SET_CAMPAIGN_DELIVERY_PERSONALIZE:
			return {
				...state,
				deliverySchedule: {
					...state.deliverySchedule,
					personalize: action.payload,
				},
			};
		default:
			return state;
	}
};

/**
 * Campaigns table reducer
 * @param {*} state
 * @param {*} action
 */
export const campaignTableReducer = (
	state = {
		loading: true,
		data: [],
		error: null,
	},
	action
) => {
	switch (action.type) {
		case GET_CAMPAIGN_DATA_TABLE_PENDING:
			return {
				...state,
				loading: true,
			};
		case GET_CAMPAIGN_DATA_TABLE:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case GET_CAMPAIGN_DATA_TABLE_ERROR:
			return {
				...state,
				loading: true,
				error: action.payload,
			};
		default:
			return state;
	}
};
