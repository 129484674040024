/**
 * Import constants
 */
import { GET_EXPIRED_DATA, GET_EXPIRED_DATA_PENDING, GET_EXPIRED_DATA_ERROR } from '../actions/constants';

/**
 * Initial state
 */
const initialState = {
	loading: true,
	data: {},
	error: null
};

/**
 * Delayed data reducer
 * @param {*} state
 * @param {*} action
 */
export const expiredReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_EXPIRED_DATA_PENDING:
			return {
				...state,
				loading: true
			};
		case GET_EXPIRED_DATA:
			return {
				...state,
				loading: false,
				data: Object.assign({}, state.data, action.payload),
				error: null
			};
		case GET_EXPIRED_DATA_ERROR:
			return {
				...state,
				loading: true,
				error: action.payload
			};
		default:
			return state;
	}
};
