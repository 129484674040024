/**
 * LOGIN PAGE
 */
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const AUTH_FAIL = 'AUTH_FAIL';

/**
 * DATE CONSTANTS
 */
export const ADD_TODAY = 'ADD_TODAY';
export const ADD_MONTH = 'ADD_MONTH';
export const UPDATE_MONTH = 'UPDATE_MONTH';

/**
 * DASHBOARD PAGE
 */
export const GET_DASHBOARD_DATA_PENDING = 'GET_DASHBOARD_DATA_PENDING';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR';
export const GET_TODAY_SOFAR = 'GET_TODAY_SOFAR';
export const GET_MONTH_SOFAR = 'GET_MONTH_SOFAR';
export const GET_CONTACTS_SOFAR = 'GET_CONTACTS_SOFAR';
export const GET_LISTS_SOFAR = 'GET_LISTS_SOFAR';
export const GET_ACTIVITY_SOFAR = 'GET_ACTIVITY_SOFAR';

/**
 * SUMMARY FOR DASHBOARD PAGE
 */
export const GET_SUMMARY_DATA = 'GET_SUMMARY_DATA';
export const GET_SUMMARY_DATA_PENDING = 'GET_SUMMARY_DATA_PENDING';
export const GET_SUMMARY_DATA_ERROR = 'GET_SUMMARY_DATA_ERROR';

/**
 * ACCEPTED PAGE
 */
export const GET_ACCEPTANCE_DATA = 'GET_ACCEPTANCE_DATA';
export const GET_ACCEPTANCE_DATA_PENDING = 'GET_ACCEPTANCE_DATA_PENDING';
export const GET_ACCEPTANCE_DATA_ERROR = 'GET_ACCEPTANCE_DATA_ERROR';

/**
 * DELAYED PAGE
 */
export const GET_DELAYED_DATA = 'GET_DELAYED_DATA';
export const GET_DELAYED_DATA_PENDING = 'GET_DELAYED_DATA_PENDING';
export const GET_DELAYED_DATA_ERROR = 'GET_DELAYED_DATA_ERROR';

/**
 * EXPIRED PAGE
 */
export const GET_EXPIRED_DATA = 'GET_EXPIRED_DATA';
export const GET_EXPIRED_DATA_PENDING = 'GET_EXPIRED_DATA_PENDING';
export const GET_EXPIRED_DATA_ERROR = 'GET_EXPIRED_DATA_ERROR';

/**
 * BOUNCED PAGE
 */
export const GET_BOUNCED_DATA = 'GET_BOUNCED_DATA';
export const GET_BOUNCED_DATA_PENDING = 'GET_BOUNCED_DATA_PENDING';
export const GET_BOUNCED_DATA_ERROR = 'GET_BOUNCED_DATA_ERROR';

/**
 * REJECTED PAGE
 */
export const GET_REJECTED_DATA_PENDING = 'GET_REJECTED_DATA_PENDING';
export const GET_REJECTED_DATA = 'GET_REJECTED_DATA';
export const GET_REJECTED_DATA_ERROR = 'GET_REJECTED_DATA_ERROR';

/**
 * RECIPIENT PAGE
 */
export const GET_RECIPIENTS_DATA = 'GET_RECIPIENTS_DATA';
export const GET_RECIPIENTS_DATA_PENDING = 'GET_RECIPIENTS_DATA_PENDING';
export const GET_RECIPIENTS_DATA_ERROR = 'GET_RECIPIENTS_DATA_ERROR';

/**
 * MESSAGE EVENTS PAGE
 */
export const GET_EVENTS_DATA = 'GET_EVENTS_DATA';
export const GET_EVENTS_DATA_PENDING = 'GET_EVENTS_DATA_PENDING';
export const GET_EVENTS_DATA_ERROR = 'GET_EVENTS_DATA_ERROR';

/**
 * USERS PAGE
 */
export const GET_USERS_DATA = 'GET_USERS_DATA';
export const GET_USERS_DATA_PENDING = 'GET_USERS_DATA_PENDING';
export const GET_USERS_DATA_ERROR = 'GET_USERS_DATA_ERROR';

/**
 * API PAGE
 */
export const GET_API_KEY_DATA = 'GET_API_KEY_DATA';
export const GET_API_KEY_DATA_PENDDING = 'GET_API_KEY_DATA_PENDDING';
export const GET_API_KEY_DATA_ERROR = 'GET_API_KEY_DATA_ERROR';

/**
 * DOMAINS PAGE
 */
export const GET_SENDING_DOMAINS_DATA_PENDDING =
	'GET_SENDING_DOMAINS_DATA_PENDDING';
export const GET_SENDING_DOMAINS_DATA = 'GET_SENDING_DOMAINS_DATA';
export const GET_SENDING_DOMAINS_DATA_ERROR = 'GET_SENDING_DOMAINS_DATA_ERROR';
export const GET_TRACKING_DOMAINS_DATA_PENDDING =
	'GET_TRACKING_DOMAINS_DATA_PENDDING';
export const GET_TRACKING_DOMAINS_DATA = 'GET_TRACKING_DOMAINS_DATA';
export const GET_TRACKING_DOMAINS_DATA_ERROR =
	'GET_TRACKING_DOMAINS_DATA_ERROR';

/**
 * EMAIL TEMPLATE PAGE
 */
export const GET_EMAILS_DATA = 'GET_EMAILS_DATA';
export const GET_EMAILS_DATA_PENDDING = 'GET_EMAILS_DATA_PENDDING';
export const GET_EMAILS_DATA_ERROR = 'GET_EMAILS_DATA_ERROR';

/**
 * SEARCH PAGE
 */
export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';
export const GET_SEARCH_DATA_PENDDING = 'GET_SEARCH_DATA_PENDDING';
export const GET_SEARCH_DATA_ERROR = 'GET_SEARCH_DATA_ERROR';

/**
 * CAMPAIGN PAGE
 */
export const GET_CAMPAIGN_DATA_TABLE = 'GET_CAMPAIGN_DATA_TABLE';
export const GET_CAMPAIGN_DATA_TABLE_PENDING =
	'GET_CAMPAIGN_DATA_TABLE_PENDING';
export const GET_CAMPAIGN_DATA_TABLE_ERROR = 'GET_CAMPAIGN_DATA_TABLE_ERROR';
export const GET_RECIPIENT_DATA = 'GET_RECIPIENT_DATA';
export const GET_RECIPIENT_PENDDING = 'GET_RECIPIENT_PENDDING';
export const GET_RECIPIENT_ERROR = 'GET_RECIPIENT_ERROR';

export const INCREASE_CAMPAIGN_STEP = 'INCREASE_CAMPAIGN_STEP';
export const DECREASE_CAMPAIGN_STEP = 'DECREASE_CAMPAIGN_STEP';
export const UPDATE_CAMPAIGN_TYPE = 'UPDATE_CAMPAIGN_TYPE';
export const UPDATE_CAMPAIGN_NAME = 'UPDATE_CAMPAIGN_NAME';
export const UPDATE_CAMPAIGN_ERROR = 'UPDATE_CAMPAIGN_ERROR';
export const ADD_CAMPAIGN_SELECTED_ROW = 'ADD_CAMPAIGN_SELECTED_ROW';
export const REMOVE_CAMPAIGN_SELECTED_ROW = 'REMOVE_CAMPAIGN_SELECTED_ROW';

export const ADD_CAMPAIGN_TEMPLATE_TYPE = 'ADD_CAMPAIGN_TEMPLATE_TYPE';
export const REMOVE_CAMPAIGN_TEMPLATE_TYPE = 'REMOVE_CAMPAIGN_TEMPLATE_TYPE';
export const RESET_CAMPAIGN_TEMPLATE_TYPE = 'RESET_CAMPAIGN_TEMPLATE_TYPE';

export const ADD_CAMPAIGN_DOMAIN = 'ADD_CAMPAIGN_DOMAIN';
export const ADD_CAMPAIGN_ADDRESS = 'ADD_CAMPAIGN_ADDRESS';
export const ADD_CAMPAIGN_SENDER = 'ADD_CAMPAIGN_SENDER';
export const ADD_CAMPAIGN_SENDER_NAME = 'ADD_CAMPAIGN_SENDER_NAME';
export const RESET_CAMPAIGN = 'RESET_CAMPAIGN';
export const EDIT_STANDART_CAMPAIGN = 'EDIT_STANDART_CAMPAIGN';
export const GET_CAMPAIGN_DATA_RESET = 'GET_CAMPAIGN_DATA_RESET';
export const SET_CAMPAIGN_DELIVERY_DATA = 'SET_CAMPAIGN_DELIVERY_DATA';
export const SET_CAMPAIGN_DELIVERY_DATE = 'SET_CAMPAIGN_DELIVERY_DATE';
export const SET_CAMPAIGN_DELIVERY_TIMEZONE = 'SET_CAMPAIGN_DELIVERY_TIMEZONE';
export const SET_CAMPAIGN_DELIVERY_PERSONALIZE =
	'SET_CAMPAIGN_DELIVERY_PERSONALIZE';
