import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//Reducers
import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import rtlReducer from './rtlReducer';
import { addTodayReducer, addMonthReducer } from './dateReducer';
import { dashboardDataReducer } from './dashboardReducer';
import { summaryReducer } from './summaryReducer';
import { acceptanceDataReducer } from './acceptanceReducer';
import { delayedReducer } from './delayedReducer';
import { expiredReducer } from './expiredReducer';
import { bouncedReducer } from './bouncedReducer';
import { rejectedReducer } from './rejectedReducer';
import { recipientsEngagementReducer } from './recipientsEngagementReducer';
import { messageEventsReducer } from './messageEventsReducer';
import { usersReducer } from './usersReducer';
import { apiKeysReducer } from './apiKeysReducer';
import { sendingDomainsReducer } from './sendingDomainsReducer';
import { trackingDomainsReducer } from './trackingDomainsReducer';
import { authReducer } from './authReducer';
import { emailTemplateReducer } from './emailTemplateReducer';
import { searchReducer } from './searchReducer';
import { recipientsListsReducer } from './recipientsListsReducer';
import { campaignReducer, campaignTableReducer } from './campaignReducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['today', 'month', 'auth', 'recipientsLists', 'campaign'],
};

const rootReducer = combineReducers({
	auth: authReducer,
	//Theme reducers
	theme: themeReducer,
	sidebar: sidebarReducer,
	rtl: rtlReducer,

	//Report reducers
	today: addTodayReducer,
	month: addMonthReducer,
	dashboardData: dashboardDataReducer,
	summary: summaryReducer,
	acceptanceData: acceptanceDataReducer,
	delayedData: delayedReducer,
	expiredData: expiredReducer,
	bouncedData: bouncedReducer,
	rejectedData: rejectedReducer,
	recipientsEngagementData: recipientsEngagementReducer,
	eventsData: messageEventsReducer,
	users: usersReducer,
	apiKeys: apiKeysReducer,
	sendingDomains: sendingDomainsReducer,
	trackingDomains: trackingDomainsReducer,
	emailTemplates: emailTemplateReducer,
	searchData: searchReducer,
	recipientsLists: recipientsListsReducer,
	campaign: campaignReducer,
	campaignTableData: campaignTableReducer,
});

export default persistReducer(persistConfig, rootReducer);
