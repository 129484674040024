import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from '../../../redux/actions/authActions';
import { Link } from 'react-router-dom';

const TopbarMenuLinks = (props) => {
	const { title, icon, path } = props;

	const dispatch = useDispatch();

	const handleLogOut = () => {
		dispatch(logOut());
	};

	return (
		<Link className='topbar__link' to={path} onClick={handleLogOut}>
			<span className={`topbar__link-icon lnr lnr-${icon}`} />
			<p className='topbar__link-title'>{title}</p>
		</Link>
	);
};

export default memo(TopbarMenuLinks);
