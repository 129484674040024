import {
	GET_SENDING_DOMAINS_DATA_PENDDING,
	GET_SENDING_DOMAINS_DATA,
	GET_SENDING_DOMAINS_DATA_ERROR
} from '../actions/constants';

const initialState = {
	loading: true,
	data: [],
	error: null
};

export const sendingDomainsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SENDING_DOMAINS_DATA_PENDDING:
			return {
				...state,
				loading: true
			};
		case GET_SENDING_DOMAINS_DATA:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			};
		case GET_SENDING_DOMAINS_DATA_ERROR:
			return {
				...state,
				loading: true,
				error: action.payload
			};
		default:
			return state;
	}
};
