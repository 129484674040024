import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**
 * Components
 */
import { FormHelperText } from '@material-ui/core';
import { Button } from 'reactstrap';

/**
 * Icons
 */
import EyeIcon from 'mdi-react/EyeIcon';

/**
 * Actions
 */
import { logIn } from '../../../redux/actions/authActions';
const LoginForm = () => {
	//Hooks
	let history = useHistory();
	const dispatch = useDispatch();

	const [password, setPassword] = useState(false);
	const [error, setError] = useState(false);

	const showPassword = () => {
		setPassword(!password);
	};

	const handlerSubmit = (data) => {
		dispatch(
			logIn(data.email, data.password, setError, () => {
				history.push('/report/dashboard');
			})
		);
	};

	/**
	 * Form validation
	 */
	const validationSchema = yup.object({
		email: yup
			.string()
			.email('Please enter a valid email.')
			.required('The filed is reguired.'),
		password: yup
			.string()
			.min(6, 'Please enter a valid password.')
			.max(33, 'Please enter a valid password.')
			.required('The filed is reguired.'),
	});

	/**
	 * Initial state
	 */
	const init = {
		email: '',
		password: '',
	};

	return (
		<Formik
			initialValues={init}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting }) => {
				setSubmitting(true);
				handlerSubmit(data);
				setSubmitting(false);
			}}>
			{({ values, isSubmitting, errors }) => (
				<Form className='form'>
					<div className='form__form-group'>
						<span className='form__form-group-label'>Email</span>
						<div className='form__form-group-field'>
							<Field
								name='email'
								component='input'
								type='text'
								placeholder='Email'
								autoComplete='off'
								style={{ borderRadius: '5px' }}
							/>
						</div>
						<FormHelperText className='Mui-error'>
							{errors.email && errors.email}
						</FormHelperText>
					</div>
					<div className='form__form-group'>
						<span className='form__form-group-label'>Password</span>
						<div className='form__form-group-field'>
							<Field
								name='password'
								component='input'
								type={password ? 'text' : 'password'}
								placeholder='Password'
								autoComplete='off'
								style={{
									borderTopLeftRadius: '5px',
									borderBottomLeftRadius: '5px',
								}}
							/>
							<button
								className={`form__form-group-button${
									password ? ' active' : ''
								}`}
								onClick={showPassword}
								type='button'
								style={{
									borderTopRightRadius: '5px',
									borderBottomRightRadius: '5px',
								}}>
								<EyeIcon />
							</button>
						</div>
						<FormHelperText className='Mui-error'>
							{(errors.password && errors.password) || (error && error)}
						</FormHelperText>
					</div>

					<Button
						type='submit'
						disabled={isSubmitting || Object.entries(errors).length > 0}
						className='btn btn-primary account__btn account__btn--small d-flex justify-content-center'>
						<p>Sign In</p>
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default LoginForm;
