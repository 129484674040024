import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import requireAuth from '../../hoc/requireAuth';

import {
	changeMobileSidebarVisibility,
	changeSidebarVisibility,
} from '../../redux/actions/sidebarActions';

class Layout extends PureComponent {
	changeSidebarVisibility = () => {
		const { dispatch } = this.props;
		dispatch(changeSidebarVisibility());
	};

	changeMobileSidebarVisibility = () => {
		const { dispatch } = this.props;
		dispatch(changeMobileSidebarVisibility());
	};

	render() {
		const { sidebar } = this.props;

		const layoutClass = classNames({
			layout: true,
			'layout--collapse': sidebar.collapse,
		});

		return (
			<div className={layoutClass}>
				<Topbar
					changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
					changeSidebarVisibility={this.changeSidebarVisibility}
				/>
				<Sidebar
					sidebar={sidebar}
					changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
				/>
			</div>
		);
	}
}

export default requireAuth(
	withRouter(
		connect((state) => ({
			sidebar: state.sidebar,
		}))(Layout)
	)
);
