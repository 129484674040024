import React from 'react';
import LoginForm from './components/LoginForm';
import { ReactComponent as Logo } from '../../assets/login_logo.svg';

const LogIn = () => {
	return (
		<div className='account'>
			<div className='account__wrapper'>
				<div className='account__card'>
					<div className='account__head'>
						<p className='account__title'>Welcome to</p>
						<Logo style={{ width: '180px', marginTop: '10px' }} />
					</div>
					<LoginForm />
				</div>
			</div>
		</div>
	);
};

export default LogIn;
