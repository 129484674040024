/* eslint-disable camelcase */
import common_fr from './fr/common.json';
import common_en from './en/common.json';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	en: {
		common: common_en,
	},
	fr: {
		common: common_fr,
	},
};
