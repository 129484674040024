import React, { useState, useRef } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { useSelector } from 'react-redux';
import requireAuth from '../../../hoc/requireAuth';

const TopbarProfile = () => {
	const [collapse, setCollapse] = useState(false);
	const user = useSelector((state) => state.auth.user);
	const elRef = useRef();

	const handleClick = () => {
		if (!collapse) {
			document.addEventListener('click', handleOutsideClick, false);
		} else {
			document.removeEventListener('click', handleOutsideClick, false);
		}
		setCollapse(!collapse);
	};

	const handleOutsideClick = (e) => {
		if (elRef.current === null) {
			return;
		}
		if (elRef.current.contains(e.target)) {
			return;
		}
		setCollapse(false);
	};

	return (
		<div className='topbar__profile'>
			<button
				type='button'
				className='topbar__avatar'
				ref={elRef}
				onClick={handleClick}
			>
				<p className='topbar__avatar-name'>{user ? user.name : ''}</p>
				<DownIcon className='topbar__icon' />
			</button>
			<Collapse isOpen={collapse} className='topbar__menu-wrap'>
				<div className='topbar__menu'>
					<TopbarMenuLink title='Log Out' icon='exit' path='/' />
				</div>
			</Collapse>
		</div>
	);
};

export default requireAuth(TopbarProfile);
