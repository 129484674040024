import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';

const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const fr = `${process.env.PUBLIC_URL}/img/language/fr.png`;

const GbLng = () => (
	<span className='topbar__language-btn-title'>
		<img src={gb} alt='gb' />
		<span>EN</span>
	</span>
);

const FrLng = () => (
	<span className='topbar__language-btn-title'>
		<img src={fr} alt='fr' />
		<span>FR</span>
	</span>
);

const TopbarLanguage = (props) => {

	const [collapse, setCollapse] = useState(false)
	const [mainButtonContent, setMainButtonContent] = useState(<GbLng />)
	const elRef = useRef();

	const handleClick = () => {
		if (!collapse) {
			document.addEventListener('click', handleOutsideClick, false);
		} else {
			document.removeEventListener('click', handleOutsideClick, false);
		}
		setCollapse(!collapse);
	};

	const handleOutsideClick = (e) => {
		if (elRef.current === null) {
			return;
		}
		if (elRef.current.contains(e.target)) {
			return;
		}
		setCollapse(false);
	};

	const changeLanguage = lng => {
		const { i18n } = props;
		i18n.changeLanguage(lng);
		switch (lng) {
			case 'en':
				setMainButtonContent(<GbLng />);
				break;
			case 'fr':
				setMainButtonContent(<FrLng />);
				break;
			default:
				setMainButtonContent(<GbLng />);
				break;
		}
	};

	return (
		<div className='topbar__collapse topbar__collapse--language'>
			<button className='topbar__btn' type='button' ref={elRef} onClick={handleClick}>
				{mainButtonContent}
				<DownIcon className='topbar__icon' />
			</button>
			<Collapse isOpen={collapse} className='topbar__collapse-content topbar__collapse-content--language'>
				<button className='topbar__language-btn' type='button' onClick={() => changeLanguage('en')}>
					<GbLng />
				</button>
				<button className='topbar__language-btn' type='button' onClick={() => changeLanguage('fr')}>
					<FrLng />
				</button>
			</Collapse>
		</div>
	);
}

export default withTranslation('common')(TopbarLanguage);
