import { createStore, applyMiddleware, compose } from 'redux';
import reducers from '../../redux/reducers';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
	reducers,
	composeEnhancers(applyMiddleware(thunk))
);
//Add store to Local Storage
export const persistor = persistStore(store);

// eslint-disable-next-line import/no-anonymous-default-export
export default { store, persistor };
