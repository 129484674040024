import moment from 'moment';
import { LOG_IN, LOG_OUT } from '../actions/constants';

const initState = {
	login: false,
	token: '',
	user: {},
	loginTime: '',
};

export const authReducer = (state = initState, action) => {
	const timestamp = moment().format();
	switch (action.type) {
		case LOG_IN:
			return {
				...state,
				...{
					login: true,
					token: action.payload.token,
					user: action.payload.userData,
					loginTime: timestamp,
				},
			};
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};
