import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import SidebarSearch from './SidebarSearch';

class SidebarContent extends PureComponent {
	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};

	render() {
		return (
			<>
				<div className='sidebar__content'>
					<SidebarSearch />
					<ul className='sidebar__block'>
						<SidebarLink
							title='Dashboard'
							icon='home'
							route='/report/dashboard'
							onClick={this.hideSidebar}
						/>
						<SidebarCategory title='Analytics' icon='chart-bars'>
							<SidebarLink
								title='Accepted'
								route='/report/accepted'
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title='Delayed'
								route='/report/delayed'
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title='Expired'
								route='/report/expired'
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title='Bounced'
								route='/report/bounced'
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title='Rejected'
								route='/report/rejected'
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title='Engagement'
								route='/report/recipients_engagement'
								onClick={this.hideSidebar}
							/>
						</SidebarCategory>
					</ul>
					{this.props.type === 'admin' || this.props.type === 'developer' ? (
						<ul className='sidebar__block'>
							<SidebarCategory title='Feed' icon='eye'>
								<SidebarLink
									title='Events'
									route='/report/events'
									onClick={this.hideSidebar}
								/>
							</SidebarCategory>
						</ul>
					) : null}

					{this.props.type === 'admin' ||
					this.props.type === 'developer' ||
					this.props.type === 'manager' ? (
						<>
							<ul className='sidebar__block'>
								<SidebarCategory title='Recipients' icon='users'>
									<SidebarLink
										title='Contacts'
										route='/contacts'
										onClick={this.hideSidebar}
									/>
									<SidebarLink
										title='Lists'
										route='/recipients-lists'
										onClick={this.hideSidebar}
									/>
									<SidebarLink
										title='Suppressions'
										route='/suppressions'
										onClick={this.hideSidebar}
									/>
								</SidebarCategory>
							</ul>

							<ul className='sidebar__block'>
								<SidebarCategory title='Automations' icon='rocket'>
									<SidebarLink
										title='Campaings'
										route='/campaings'
										onClick={this.hideSidebar}
									/>
									<SidebarLink
										title='Journey'
										route='/journey'
										onClick={this.hideSidebar}
									/>
								</SidebarCategory>
							</ul>
						</>
					) : null}

					{this.props.type === 'admin' ||
					this.props.type === 'developer' ||
					this.props.type === 'manager' ||
					this.props.type === 'reporter' ? (
						<ul className='sidebar__block'>
							<SidebarCategory title='Content' icon='select'>
								<SidebarLink
									title='Templates'
									route='/email-templates'
									onClick={this.hideSidebar}
								/>
							</SidebarCategory>
						</ul>
					) : null}

					{this.props.type === 'admin' ? (
						<ul className='sidebar__block'>
							<SidebarCategory title='Accounts' icon='user'>
								<SidebarLink
									title='Users'
									route='/users'
									onClick={this.hideSidebar}
								/>
							</SidebarCategory>
						</ul>
					) : null}

					{this.props.type === 'admin' || this.props.type === 'developer' ? (
						<ul className='sidebar__block'>
							<SidebarCategory title='Configuration' icon='cog'>
								<SidebarLink
									title='API keys'
									route='/api-keys'
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title='Domains'
									route='/domains'
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title='IP Pools'
									route='/ip-pools'
									onClick={this.hideSidebar}
								/>
								<SidebarLink
									title='Webhooks'
									route='/report/dashboard'
									onClick={this.hideSidebar}
								/>
							</SidebarCategory>
						</ul>
					) : null}
				</div>
			</>
		);
	}
}
function mapStateToProps(state) {
	return { type: state.auth.user ? state.auth.user.type : 'reporter' };
}

export default connect(mapStateToProps)(SidebarContent);
