/**
 * Import axios library
 */
import axios from 'axios';

/**
 * Import persistor
 */
import { persistor } from '../../containers/App/store';

/**
 * Import base api settings
 */
import { baseUrl, baseParams } from '../../apis/api';

/**
 * Import constants
 */
import { LOG_IN, LOG_OUT } from './constants';

/**
 * Log in action
 * @param {*} email
 * @param {*} password
 * @param {*} setError
 * @param {*} cb
 */

export const logIn = (email, password, setError, callback) => async (
	dispatch
) => {
	try {
		//Make request
		const response = await axios.post(baseUrl, {
			...baseParams,
			params: { email: email, password: password },
			method: 'auth.login',
		});

		//Check for errors
		if (response.data.error !== undefined) {
			if (response.data.error.code === -32001) {
				setError('Unsuccessful login. Please try again!');
			}
		} else if (
			response.data.result.success !== undefined &&
			response.data.result.success === false
		) {
			setError('Unsuccessful login. Please try again!');
		} else {
			dispatch({
				type: LOG_IN,
				payload: response.data.result,
			});
			callback();
		}
	} catch (error) {
		setError('Sorry, please try again later!');
	}
};

/**
 * Log out
 */
export const logOut = () => async (dispatch, getState) => {
	//Get data from store
	const state = getState();
	const apiKey = state.auth.token;

	await axios.post(baseUrl, {
		...baseParams,
		params: {
			apiKey,
		},
		method: 'auth.logout',
	});

	await persistor.purge();

	dispatch({
		type: LOG_OUT,
	});
};
