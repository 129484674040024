import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarSearch from './TopbarSearch';
import TopbarProfile from './TopbarProfile';
import TopbarLanguage from './TopbarLanguage';
import requireAuth from '../../../hoc/requireAuth';

class Topbar extends PureComponent {
	render() {
		const {
			changeMobileSidebarVisibility,
			changeSidebarVisibility,
		} = this.props;

		return (
			<div className='topbar'>
				<div className='topbar__wrapper'>
					<div className='topbar__left'>
						<TopbarSidebarButton
							changeMobileSidebarVisibility={changeMobileSidebarVisibility}
							changeSidebarVisibility={changeSidebarVisibility}
						/>
						<Link className='topbar__logo' to='/report/dashboard' />
						<TopbarSearch />
					</div>
					<div className='topbar__right'>
						<TopbarLanguage />
						<TopbarProfile />
					</div>
				</div>
			</div>
		);
	}
}

export default requireAuth(Topbar);
