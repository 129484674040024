/**
 * Import constants
 */
import {
	GET_DASHBOARD_DATA_PENDING,
	GET_DASHBOARD_DATA,
	GET_DASHBOARD_DATA_ERROR,
	GET_TODAY_SOFAR,
	GET_MONTH_SOFAR,
	GET_CONTACTS_SOFAR,
	GET_LISTS_SOFAR,
	GET_ACTIVITY_SOFAR,
} from '../actions/constants';

/**
 * Initial state
 */
const initialState = {
	loading: true,
	data: {},
	error: null,
};

/**
 * Dashboard data reducer
 * @param {*} state
 * @param {*} action
 */
export const dashboardDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_DASHBOARD_DATA_PENDING:
			return {
				...state,
				loading: true,
			};
		case GET_DASHBOARD_DATA:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case GET_DASHBOARD_DATA_ERROR:
			return {
				...state,
				loading: true,
				error: action.payload,
			};
		case GET_TODAY_SOFAR:
			state.data.usageDaily = action.payload.daily;
			return {
				...state,
			};
		case GET_MONTH_SOFAR:
			state.data.usageMonthly = action.payload.monthly;
			return {
				...state,
			};
		case GET_CONTACTS_SOFAR:
			state.data.totalContacts = action.payload.contacts;
			return {
				...state,
			};
		case GET_LISTS_SOFAR:
			state.data.totalLists = action.payload.lists;
			return {
				...state,
			};
		case GET_ACTIVITY_SOFAR:
			state.data.activity = action.payload;
			return {
				...state,
			};
		default:
			return state;
	}
};
