/**
 * Constants
 */
import { ADD_TODAY, ADD_MONTH, UPDATE_MONTH } from '../actions/constants';

/**
 * Add today reducer
 * @param {*} state
 * @param {*} action
 */
export const addTodayReducer = (state = [], action) => {
	switch (action.type) {
		case ADD_TODAY:
			return action.payload;
		default:
			return state;
	}
};

/**
 * Add month resucer
 * @param {*} state
 * @param {*} action
 */
export const addMonthReducer = (state = [], action) => {
	switch (action.type) {
		case ADD_MONTH:
			return action.payload;
		case UPDATE_MONTH:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};
