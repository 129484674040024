import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import SidebarContent from './SidebarContent';
import requireAuth from '../../../hoc/requireAuth';
import styled from 'styled-components';

const Sidebar = ({ changeMobileSidebarVisibility, sidebar }) => {
	const sidebarClass = classNames({
		sidebar: true,
		'sidebar--show': sidebar.show,
		'sidebar--collapse': sidebar.collapse,
	});

	return (
		<>
			<div className={sidebarClass}>
				<button
					type='button'
					className='sidebar__back'
					onClick={changeMobileSidebarVisibility}
				/>
				<Scrollbar className='sidebar__scroll scroll'>
					<div className='sidebar__wrapper sidebar__wrapper--desktop'>
						<SidebarContent onClick={() => {}} />
					</div>
					<div className='sidebar__wrapper sidebar__wrapper--mobile'>
						<SidebarContent onClick={changeMobileSidebarVisibility} />
					</div>
				</Scrollbar>
			</div>
			<StyledDiv>
				<p
					className='text-center text-secondary sidebar__block fixed-bottom bg-white'
					sidebar={sidebar}>
					{' '}
					Copyright &copy; {new Date().getFullYear()} iCOVER Services
				</p>
			</StyledDiv>
		</>
	);
};

const StyledDiv = styled.div`
	p {
		font-size: 10px;
		width: ${(props) =>
			props.children.props.sidebar.collapse ? '55px' : '240px'};
	}
`;

export default requireAuth(Sidebar);
