/**
 * Import constants
 */
import { GET_SUMMARY_DATA_PENDING, GET_SUMMARY_DATA, GET_SUMMARY_DATA_ERROR } from '../actions/constants';

/**
 * Initial state
 */
const initialState = {
	loading: true,
	data: {},
	error: null
};

/**
 * Summary data reducer
 * @param {*} state
 * @param {*} action
 */
export const summaryReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SUMMARY_DATA_PENDING:
			return {
				...state,
				loading: true
			};
		case GET_SUMMARY_DATA:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			};
		case GET_SUMMARY_DATA_ERROR:
			return {
				...state,
				loading: true,
				error: action.payload
			};
		default:
			return state;
	}
};
