import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/authActions';
import moment from 'moment';

const isTokenExpired = (date) => {
	return moment(date).isBefore(moment().subtract(2, 'hour'));
};

const requireAuth = (Component) => {
	const Wrapper = (props) => {
		const { login, loginTime } = useSelector((state) => state.auth);

		const dispatch = useDispatch();

		const checkExpired = isTokenExpired(loginTime);

		useEffect(() => {
			if (!login || checkExpired) {
				dispatch(logOut());
				props.history?.push('/');
			}
		}, [login, checkExpired, props, dispatch]);

		return <Component {...props} />;
	};
	return Wrapper;
};

export default requireAuth;
