import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import { LayoutSplashScreen } from '../../shared/components/LayoutSplashScreen';
import { Pages } from './Pages';

const Router = () => {
	return (
		<MainWrapper>
			<main>
				<Switch>
					<Route exact path='/'>
						<LogIn />
					</Route>
					<Route path='/'>
						<div>
							<Layout />
							<div className='container__wrap'>
								<Route path='/'>
									<Suspense fallback={<LayoutSplashScreen />}>
										<Pages />
									</Suspense>
								</Route>
							</div>
						</div>
					</Route>
				</Switch>
			</main>
		</MainWrapper>
	);
};

export default Router;
