import React, { PureComponent } from 'react';

//Transmission
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { config as i18nextConfig } from '../../translations';

//Store and localStorage
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

//Router
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';

import ScrollToTop from './ScrollToTop';

import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

i18next.init(i18nextConfig);

class App extends PureComponent {
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter forceRefresh={true}>
					<PersistGate persistor={persistor}>
						<I18nextProvider i18n={i18next}>
							<ScrollToTop>
								<Router />
							</ScrollToTop>
						</I18nextProvider>
					</PersistGate>
				</BrowserRouter>
			</Provider>
		);
	}
}
export default App;
