/**
 * Import constants
 */
import {
	GET_RECIPIENT_DATA,
	GET_RECIPIENT_PENDDING,
	GET_RECIPIENT_ERROR,
} from '../actions/constants';

/**
 * Initial state
 */
const initialState = {
	loading: true,
	data: [],
	error: null,
};

/**
 * API key reducer
 * @param {*} state
 * @param {*} action
 */
export const recipientsListsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_RECIPIENT_PENDDING:
			return {
				...state,
				loading: true,
			};
		case GET_RECIPIENT_DATA:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case GET_RECIPIENT_ERROR:
			return {
				...state,
				loading: true,
				error: action.payload,
			};
		default:
			return state;
	}
};
