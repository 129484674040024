import React, { PureComponent } from 'react';
import SearchIcon from 'mdi-react/SearchIcon';
import { withRouter } from 'react-router';
import CloseIcon from 'mdi-react/CloseIcon';
import classNames from 'classnames';

class SidebarSearch extends PureComponent {
	state = {
		inputOpen: true,
		query: ''
	};

	onInputOpen = e => {
		e.preventDefault();
		this.setState(prevState => ({ inputOpen: !prevState.inputOpen }));
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.history.push(`/search/${this.state.query}`);
		this.setState({ query: '' });
	};

	render() {
		const { inputOpen } = this.state;

		const searchClass = classNames({
			'sidebar__search-field': true,
			'sidebar__search-field--open': inputOpen
		});

		return (
			<form className='sidebar__search' onSubmit={this.handleSubmit}>
				<input
					placeholder='Search...'
					className={searchClass}
					value={this.state.query}
					onChange={e => this.setState({ query: e.target.value })}
				/>
				<button
					className='topbar__btn sidebar__search-btn'
					type='button'
					style={{ left: !inputOpen ? '14px' : '200px' }}
					onClick={this.onInputOpen}
				>
					{inputOpen ? <CloseIcon /> : <SearchIcon />}
				</button>
				{inputOpen && <button className='topbar__back' type='button' onClick={this.onInputOpen} />}
			</form>
		);
	}
}

export default withRouter(SidebarSearch);
