import React from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';

export const LayoutSplashScreen = () => {
	return (
		<div className='panel__refresh refresh_custom'>
			<LoadingIcon />
		</div>
	);
};
